import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { Paths } from '../../routes';
import { useAccessToken, useVerifiedToken } from '../../hooks';
import { SessionUtils } from '../../utils';

const PrivateRoute: React.FC<{
  exact?: boolean;
  path?: string;
}> = ({
  exact,
  path,
  children,
}) => {
  const history = useHistory();
  const accessToken = useAccessToken();
  const verified = useVerifiedToken(accessToken);

  useEffect(() => {
    if (verified === false) {
      SessionUtils.clearAccessToken();
      history.push(Paths.Auth);
    }
  }, [verified]);

  return (
    <Route
      exact={exact}
      path={path}
    >
      {accessToken ? children : (
        <Redirect to={Paths.Auth} />
      )}
    </Route>
  );
};

export default PrivateRoute;

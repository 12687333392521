import endpoints from './endpoints';
import config from '../../../config';
import { createApi } from '../apis';
import { BaseClient } from '..';

const AdminApi = createApi(new BaseClient({
  baseURL: config.adminApi,
}), endpoints);

export default AdminApi;

import React from 'react';

const SearchIcon: React.FC = () => {
  return (
    <div>
      <span className="fa fa-search" />
    </div>
  );
};

export default SearchIcon;

import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  NotificationBlock,
  NotificationForm,
  SquaredPurpleButton,
  NotificationEditBlock,
  SquaredDarkButton,
  NotificationDeleteModal,
} from '../../components';

import styles from './edit-notification.module.css';
import { StoreContext } from '../../store/store';
import Services from '../../services';
import { actions } from '../../store';
import { Paths } from '../../routes';
import { useAccessToken } from '../../hooks';

const EditNotification: React.FC = () => {
  const {
    state: {
      notificationsList,
    },
    dispatch,
  } = useContext(StoreContext);
  const history = useHistory();
  const { id: pathId } = useParams();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [
    sortedNotifications,
    setSortedNotifications,
  ] = useState<typeof notificationsList>([]);
  const accessToken = useAccessToken();

  useEffect(() => {
    setSortedNotifications([
      ...notificationsList.sort((first, second) => {
        const firstTimestamp = (new Date(first.releaseDate || 0)).getTime();
        const secondTimestamp = (new Date(second.releaseDate || 0)).getTime();

        return firstTimestamp - secondTimestamp;
      }),
    ]);
  }, [notificationsList]);

  const isFormDisabled = () => {
    return deleting;
  };

  const editingNotificationId = Number(pathId);
  const editingNotification = notificationsList.find((notification) => {
    return notification.id === editingNotificationId;
  });

  return (
    <div className="row">
      <div className="col-xl-7 mb-5">
        <div className={`${styles.card} card`}>
          <div className="card-body">
            {editingNotification && (
              <>
                <NotificationDeleteModal
                  notificationId={editingNotification.id}
                  isOpen={openDeleteModal}
                  onClose={() => {
                    setDeleting(false);
                    setOpenDeleteModal(false);
                  }}
                  onDeleteFinish={(deletedId) => {
                    if (deletedId) {
                      setOpenDeleteModal(false);
                      dispatch(actions.finishDeletingNotification(deletedId));
                      history.push(Paths.CreateNotification);
                    }
                    setDeleting(false);
                  }}
                />
                <NotificationForm
                  disabledForm={isFormDisabled()}
                  defaultTitle={editingNotification.title}
                  defaultDescription={editingNotification.description}
                  defaultReleaseDate={
                    editingNotification.releaseDate
                      ? new Date(editingNotification.releaseDate)
                      : null
                  }
                  defaultReleaseTime={
                    editingNotification.releaseDate
                      ? new Date(editingNotification.releaseDate)
                      : null
                  }
                  defaultProductionId={editingNotification.productionId}
                  defaultExternalLink={editingNotification.externalLink}
                  onSubmit={async ({
                    title,
                    description,
                    releaseDate,
                    productionId,
                    externalLink,
                    scheduled,
                  }) => {
                    const scheduledReleaseDate = scheduled
                      ? releaseDate && releaseDate.toISOString()
                      : null;
                    const notificationData = {
                      title,
                      description,
                      releaseDate: scheduledReleaseDate,
                      productionId,
                      externalLink,
                    };

                    return Services.Notifications.update({
                      id: editingNotificationId,
                      ...notificationData,
                    }, accessToken).then((editedNotification) => {
                      if (!editedNotification) return false;

                      dispatch(actions.finishEditingNotification({
                        id: editedNotification.id,
                        ...notificationData,
                      }));

                      history.push(Paths.CreateNotification);

                      return true;
                    });
                  }}
                >
                  {({ errors, inputDisabled }) => (
                    <div className="form-group mb-0 d-flex justify-content-end">
                      <SquaredDarkButton
                        className={`${styles.deleteButton}`}
                        type="button"
                        onClick={() => {
                          setDeleting(true);
                          setOpenDeleteModal(true);
                        }}
                        disabled={inputDisabled}
                      >
                        Delete
                      </SquaredDarkButton>
                      <SquaredPurpleButton
                        className={`${styles.submitButton}`}
                        type="submit"
                        disabled={!!errors || inputDisabled}
                      >
                        Save Changes
                      </SquaredPurpleButton>
                    </div>
                  )}
                </NotificationForm>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.cardContainer} col-xl-5 mb-5`}>
        <div className={`${styles.card} card`}>
          <div className="card-body">
            <h4 className={`${styles.viewNotificationsHeader} card-title font-weight-bolder`}>
              Scheduled Notifications
            </h4>
            <div className="card-subtitle">
              {sortedNotifications.map((notification) => {
                return editingNotificationId === notification.id ? (
                  <NotificationEditBlock
                    key={notification.id}
                    className={styles.selectedNotificationDivider}
                    title={notification.title}
                    description={notification.description}
                  />
                ) : (
                  <NotificationBlock
                    key={notification.id}
                    className={styles.eventDivider}
                    id={notification.id}
                    title={notification.title}
                    description={notification.description}
                    releaseDate={notification.releaseDate}
                    disabled
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNotification;

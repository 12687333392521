import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import {
  CardDisplay,
  DarkButton,
  ProductionCard,
  ProductionSearchBar,
  Icons,
} from '../../components';
import styles from './add-content.module.css';
import { Paths } from '../../routes';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';
import Services from '../../services';
import { getRandomInt } from '../../utils/number-utils';
import { useAccessToken } from '../../hooks';

const AddContent: React.FC = () => {
  const {
    state: {
      productionsData,
      productionList,
      searchingProductions,
      productionSearchQuery,
      loadingMore,
      trendingProductions,
      productionCurrentPage,
      trendingProductionsBeingSaved,
      isTrendingLoaded,
      trendingSaveFailed,
    },
    dispatch,
  } = useContext(StoreContext);
  const accessToken = useAccessToken();

  return (
    <div className="container-fluid">
      <div className="row justify-content-between align-items-center pb-5">
        <div className="col-6 d-flex">
          <ProductionSearchBar />
        </div>
        <div className={`${styles.saving} col-1 pl-0`}>
          {trendingProductionsBeingSaved ? (
            <span>Saving...</span>
          ) : trendingSaveFailed && (
            <span>Saving failed...</span>
          )}
        </div>
        <div className="col">
          <div className="row justify-content-end">
            <div className={`${styles.activeTab} col-auto pb-2 pt-2`}>
              Add Content
            </div>
            <div className={`${styles.inactiveTab} col-auto pb-2 pt-2`}>
              <Link to={Paths.WhatsPoppin}>
                What's Poppin'
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          {searchingProductions || !isTrendingLoaded ? (
            <Icons.Searching className={`${styles.searching}`} />
          ) : (
            <CardDisplay>
              {productionList.map((productionId) => {
                return (
                  <ProductionCard
                    key={productionsData[productionId].id}
                    id={productionsData[productionId].id}
                    poster={productionsData[productionId].poster}
                    type={productionsData[productionId].type}
                    genres={[...productionsData[productionId].genres]}
                    title={productionsData[productionId].title}
                    trending={trendingProductions.includes(productionsData[productionId].id)}
                  />
                );
              })}
            </CardDisplay>
          )}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          {!searchingProductions && isTrendingLoaded && (
            <DarkButton
              disabled={searchingProductions || loadingMore || !isTrendingLoaded}
              onClick={() => {
                const identifier = `${getRandomInt()}_load-more_${productionSearchQuery}`;
                dispatch(actions.startLoadingMoreProductions(productionSearchQuery, identifier));
                Services.Productions.search(
                  productionSearchQuery,
                  {
                    currentPage: productionCurrentPage,
                    exclude: [...trendingProductions, ...productionList],
                    count: 12,
                    maxPageSearches: 10,
                  },
                  accessToken,
                ).then(({ results, page }) => {
                  dispatch(actions.loadedMoreProductions(
                    identifier,
                    results,
                    page,
                  ));
                }).catch(() => {});
              }}
            >
              Load more
            </DarkButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddContent;

import React from 'react';
import styles from './squared-red-button.module.css';
import RedButton from '../red-button';

const SquaredRedButton: React.FC<{
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
}> = ({
  className = '',
  disabled,
  onClick,
  type,
  children,
}) => {
  return (
    <RedButton
      type={type}
      className={`${className} ${styles.squared}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </RedButton>
  );
};

export default SquaredRedButton;

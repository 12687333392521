import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';

import styles from './right-drop-zone.module.css';
import { DragItemTypes } from '../../draggable-card-display';
import { StoreContext } from '../../../store/store';
import { actions } from '../../../store';

const RightDropZone: React.FC<{
  position: number;
}> = ({
  position,
}) => {
  const { dispatch } = useContext(StoreContext);
  const [{ isOver }, drop] = useDrop({
    accept: DragItemTypes.CARD,
    drop: (
      item: {
        type: string;
        id?: number;
      },
      monitor,
    ) => {
      if (monitor.didDrop()) return;
      if (!item.id) return;

      dispatch(actions.changeTrendingProductionOrder(item.id, position));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`${styles.leftDropZone}`}
    >
      <div className={styles.body} />
      <div className={`${styles.gutter} ${isOver ? styles.highlighted : ''}`} />
    </div>
  );
};

export default RightDropZone;

import React, { useContext } from 'react';
import SearchBar from '../search-bar';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';

const ProductionSearchBar: React.FC = () => {
  const {
    state: {
      productionSearchQuery,
    },
    dispatch,
  } = useContext(StoreContext);

  return (
    <SearchBar
      placeholder="Search movies and tv shows"
      value={productionSearchQuery}
      onChange={(query) => {
        dispatch(actions.changeProductionsSearchQuery(query));
      }}
    />
  );
};

export default ProductionSearchBar;

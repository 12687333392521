import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { VodProviderPopupContext } from '../contexts/vod-provider-popup';

const useVodProviderPopup = () => {
  const { pathname } = useLocation();
  const [production, setProduction] = useContext(VodProviderPopupContext);

  useEffect(() => {
    if (production) {
      setProduction(undefined);
    }
  }, [pathname]);

  const closePopup = () => {
    setProduction(undefined);
  };

  const openPopup = (productionId: number, productionName: string) => {
    setProduction({
      productionId,
      productionName,
    });
  };

  return {
    open: !!production,
    closePopup,
    openPopup,
    production,
  };
};

export default useVodProviderPopup;

import * as DateFns from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

type IgnoredTime = Partial<{
  seconds: boolean;
  milliseconds: boolean;
}>;

export type DateComparisonOperator = '=' | '<' | '<=' | '>' | '>=';

const getLocaleTimestamp = (
  date: Date | number,
  ignore?: IgnoredTime,
) => {
  const dateObject = typeof date === 'number' ? new Date(date) : date;
  const secondsTimestamp = dateObject.getSeconds() * 1000;
  const msTimestamp = dateObject.getMilliseconds();

  let timestamp = dateObject.getTime();

  if (ignore?.seconds) timestamp -= secondsTimestamp;
  if (ignore?.milliseconds) timestamp -= msTimestamp;

  return timestamp;
};

export const getTimestampDifference = (
  first: Date | number,
  second: Date | number,
  ignore?: IgnoredTime,
) => {
  return getLocaleTimestamp(first, ignore) - getLocaleTimestamp(second, ignore);
};

export const compareTime = (
  first: Date | number,
  operator: '=' | '<' | '<=' | '>' | '>=',
  second: Date | number,
  ignore?: IgnoredTime,
) => {
  const difference = getTimestampDifference(first, second, ignore);

  switch (operator) {
    case '=':
      return difference === 0;
    case '<':
      return difference < 0;
    case '<=':
      return difference <= 0;
    case '>':
      return difference > 0;
    case '>=':
      return difference >= 0;
    default:
      return undefined;
  }
};

export const getLocaleStartOfToday = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getLocaleNextYear = () => new Date().getFullYear() + 1;

export const isValid = (date: Date | null): date is Date => {
  return DateFns.isValid(date);
};

export const mergeLocaleTimeIntoDate = (
  date: Date | null,
  time: Date | null,
  overrides?: Partial<{
    time: Partial<{
      hours: number;
      minutes: number;
      seconds: number;
      milliseconds: number;
    }>;
  }>,
) => {
  if (!isValid(date) || !isValid(time)) return null;

  const dateTime = new Date(date.getTime());
  const hours = overrides?.time?.hours ?? time.getHours();
  const minutes = overrides?.time?.minutes ?? time.getMinutes();
  const seconds = overrides?.time?.seconds ?? time.getSeconds();
  const milliseconds = overrides?.time?.milliseconds ?? time.getMilliseconds();

  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  dateTime.setSeconds(seconds);
  dateTime.setMilliseconds(milliseconds);

  return dateTime;
};

export const formatWithTimezone = (
  date: Date | string | number,
  outputFormat: string,
  timeZone: string,
) => {
  const timeZoneDate = utcToZonedTime(date, timeZone);
  return format(timeZoneDate, outputFormat, {
    timeZone,
    locale: enUS,
  });
};

export const formatTimezone = (timeZone: string) => {
  return format(Date.now(), 'zzz', {
    timeZone,
    locale: enUS,
  });
};

import React, { useContext } from 'react';
import SearchBar from '../search-bar';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';

const TrendingSearchBar: React.FC = () => {
  const {
    state: {
      trendingSearchQuery,
    },
    dispatch,
  } = useContext(StoreContext);
  return (
    <SearchBar
      placeholder="Search movies and tv shows"
      value={trendingSearchQuery}
      onChange={(query) => {
        dispatch(actions.searchTrending(query));
      }}
    />
  );
};

export default TrendingSearchBar;

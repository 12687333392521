import { AdminApi } from './apis';
import { ApiResponse } from './apis/apis';

type NotificationAttribute = {
  title: string;
  description: string;
  releaseDate: string | null | undefined;
  productionId: number | null;
  externalLink: string | null | undefined;
};

export type NotificationModel = NotificationAttribute & {
  id: number;
};

class NotificationsService {
  public static getScheduled = async (accessToken: string) => {
    try {
      const response: ApiResponse<NotificationModel[]> = await AdminApi.getScheduledNotifications({
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data || [];
    } catch (err) {
      return [];
    }
  };

  public static getById = async (notificationId: number, accessToken: string) => {
    try {
      const response: ApiResponse<NotificationModel> = await AdminApi.getNotificationById({
        path: {
          notificationId,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data;
    } catch (err) {
      return null;
    }
  };

  public static save = async (notification: NotificationAttribute, accessToken: string) => {
    try {
      const response: ApiResponse<NotificationModel> = await AdminApi.saveNotification({
        request: {
          ...notification,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data;
    } catch (err) {
      return null;
    }
  };

  public static update = async (notification: NotificationModel, accessToken: string) => {
    try {
      const response: ApiResponse<NotificationModel> = await AdminApi.updateNotification({
        request: {
          ...notification,
        },
        path: {
          notificationId: notification.id,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data;
    } catch (err) {
      return null;
    }
  };

  public static delete = async (notificationId: number, accessToken: string) => {
    try {
      const response: ApiResponse<NotificationModel> = await AdminApi.deleteNotification({
        path: {
          notificationId,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data;
    } catch (err) {
      return null;
    }
  };
}

export default NotificationsService;

import { AdminApi } from './apis';
import { ApiResponse } from './apis/apis';

class AdministratorsService {
  public static login = async (credentials: {
    email: string;
    password: string;
  }) => {
    try {
      const response: ApiResponse<{
        accessToken: string;
      }> = await AdminApi.login({
        request: {
          email: credentials.email,
          password: credentials.password,
        },
      });

      return response?.data.accessToken;
    } catch (err) {
      return undefined;
    }
  };

  public static logout = async (accessToken: string) => {
    try {
      const response = await AdminApi.logout({
        request: {
          accessToken,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return !!response;
    } catch (err) {
      return false;
    }
  };

  public static forgotPassword = async (email: string) => {
    try {
      const response = await AdminApi.forgotPassword({
        request: {
          email,
        },
      });

      return !!response;
    } catch (err) {
      return false;
    }
  };

  public static verifyResetToken = async ({
    email,
    resetToken,
  }: {
    email: string;
    resetToken: string;
  }) => {
    try {
      const response = await AdminApi.verifyResetToken({
        request: {
          email,
          resetToken,
        },
      });

      return !!response;
    } catch (err) {
      return false;
    }
  };

  public static resetPassword = async ({
    email,
    password,
    resetToken,
  }: {
    email: string;
    password: string;
    resetToken: string;
  }) => {
    try {
      const response = await AdminApi.resetPassword({
        request: {
          email,
          password,
          resetToken,
        },
      });

      return !!response;
    } catch (err) {
      return false;
    }
  };

  public static verifySession = async (accessToken: string) => {
    try {
      const response = await AdminApi.verifySession({
        request: {
          accessToken,
        },
      });

      return !!response;
    } catch (err) {
      return false;
    }
  };
}

export default AdministratorsService;

import React, { useEffect, useContext, useState } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import {
  TopHeaderBar,
} from '../../components';
import { Paths } from '../../routes';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';
import Services from '../../services';
import Pages from '..';
import { getRandomInt } from '../../utils/number-utils';
import { Production } from '../../store/initial-state';
import { useAccessToken } from '../../hooks';

const ManageContent: React.FC = () => {
  const {
    state: {
      productionSearchQuery,
      productionCurrentPage,
      trendingProductions,
      trendingProductionsPendingSaving,
      trendingProductionsBeingSaved,
      isTrendingLoaded,
    },
    dispatch,
  } = useContext(StoreContext);
  const [timeoutId, setTimeoutId] = useState(0);
  const [canStartSaving, setCanStartSaving] = useState(false);
  const [loadedTrending, setLoadedTrending] = useState<Production[] | null>(null);
  const accessToken = useAccessToken();

  useEffect(() => {
    if (trendingProductionsBeingSaved) {
      setTimeout(() => {
        Services.Productions.saveTrending(
          [...trendingProductionsBeingSaved],
          accessToken,
        ).then((success) => {
          dispatch(actions.finishSavingTrending(success));
        });
      }, 1500);
    }
  }, [trendingProductionsBeingSaved]);

  useEffect(() => {
    if (!trendingProductionsBeingSaved && trendingProductionsPendingSaving) {
      dispatch(actions.startSavingTrending());
    }
  }, [trendingProductionsPendingSaving, trendingProductionsBeingSaved]);

  useEffect(() => {
    if (isTrendingLoaded) {
      if (!canStartSaving) {
        setCanStartSaving(true);
      } else {
        dispatch(actions.queueTrendingForSaving());
      }
    }
  }, [trendingProductions]);

  useEffect(() => {
    Services.Productions.getTrending(accessToken).then((productions) => {
      if (!productions) return;
      setLoadedTrending([...productions]);
    });
  }, []);

  useEffect(() => {
    if (!loadedTrending) return;
    dispatch(actions.loadedTrending([...loadedTrending]));
  }, [loadedTrending]);

  useEffect(() => {
    return () => {
      dispatch(actions.exitManageContent());
    };
  }, []);

  useEffect(() => {
    if (isTrendingLoaded) {
      window.clearTimeout(timeoutId);
      const identifier = `${getRandomInt()}_search_${productionSearchQuery}`;
      dispatch(actions.startSearchingProductions(productionSearchQuery, identifier));
      setTimeoutId(window.setTimeout(() => {
        Services.Productions.search(
          productionSearchQuery,
          {
            currentPage: productionCurrentPage,
            exclude: [...trendingProductions],
            count: 12,
            maxPageSearches: 10,
          },
          accessToken,
        ).then(({ results, page }) => {
          dispatch(actions.finishSearchingProductions(
            identifier,
            results,
            page,
          ));
        }).catch(() => {});
      }, 1000));
    }
  }, [productionSearchQuery, dispatch, isTrendingLoaded]);

  return (
    <div className="mb-5">
      <TopHeaderBar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            <Switch>
              <Route path={Paths.AddContent}>
                <Pages.AddContent />
              </Route>
              <Route path={Paths.WhatsPoppin}>
                <Pages.WhatsPoppin />
              </Route>
              <Route exact path={Paths.Manage}>
                <Redirect to={Paths.AddContent} />
              </Route>
              <Route path={Paths.Default}>
                <Redirect to={Paths.NotFound} />
              </Route>
            </Switch>
          </div>
          <div className="col-1" />
        </div>
      </div>
    </div>
  );
};

export default ManageContent;

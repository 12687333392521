import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';

import styles from './drop-zone.module.css';
import LeftDropZone from '../left-drop-zone';
import { DragItemTypes } from '../../draggable-card-display';
import RightDropZone from '../right-drop-zone';

const DropZone: React.FC<{
  position: number;
}> = ({
  position,
}) => {
  const [{ canDrop }, drop] = useDrop({
    accept: DragItemTypes.CARD,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });
  /*
    TODO: Chrome has a bug where if you modify the DOM (even CSS)
    on DragStart, the drag will end immediately. Until then, the workaround
    is to set any DOM modifications on a timer :\.
    https://github.com/react-dnd/react-dnd/issues/477
  */
  const [isDragging, setDragging] = useState(canDrop);
  useEffect(() => {
    setTimeout(() => setDragging(canDrop), 10);
  }, [canDrop]);

  return (
    <div
      ref={drop}
      className={`${styles.dropZone} ${isDragging ? styles.dragging : styles.notDragging}`}
    >
      <LeftDropZone
        position={position}
      />
      <RightDropZone
        position={position + 1}
      />
    </div>
  );
};

export default DropZone;

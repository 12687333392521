export const Home = '/';

export const Auth = '/auth';
export const Login = `${Auth}/login`;
export const ForgotPassword = `${Auth}/forgot-password`;
export const VerifyResetToken = `${ForgotPassword}/verify`;
export const ResetPassword = `${Auth}/reset-password`;

export const Manage = '/manage-content';
export const AddContent = `${Manage}/add-content`;
export const WhatsPoppin = `${Manage}/whats-poppin`;

export const LiveEvents = '/live-events';
export const CreateEvent = `${LiveEvents}/create`;
export const EditEvent = `${LiveEvents}/:id/edit`;

export const Notifications = '/notifications';
export const CreateNotification = `${Notifications}/create`;
export const EditNotification = `${Notifications}/:id/edit`;

export const NotFound = '/not-found';
export const Default = '*';

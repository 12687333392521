import { AdminApi } from './apis';
import { ApiResponse } from './apis/apis';

export type VodProviderModel = {
  id: number;
  name: string;
  displayPicture: string;
};

class VodProvidersService {
  public static getAll = async (accessToken: string) => {
    try {
      const response: ApiResponse<VodProviderModel[]> = await AdminApi.getVodProviders({
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data || [];
    } catch (err) {
      return [];
    }
  };

  public static getByProduction = async (productionId: number, accessToken: string) => {
    try {
      const response: ApiResponse<VodProviderModel[]> = await AdminApi.getVodProvidersByProduction({
        path: {
          productionId,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data || [];
    } catch (err) {
      return [];
    }
  };

  public static saveToProduction = async (
    productionId: number,
    vodProviderIds: number[],
    accessToken: string,
  ) => {
    try {
      await AdminApi.saveVodProvidersToProduction({
        request: {
          vodProviderIds,
        },
        path: {
          productionId,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return true;
    } catch (err) {
      return false;
    }
  };
}

export default VodProvidersService;

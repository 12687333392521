enum ActionType {
  StartLoadingMoreProductions = 'start-loading-more-productions',
  LoadedMoreProductions = 'loaded-more-productions',
  TrendProduction = 'trend-production',
  UndoTrendProduction = 'undo-trend-production',
  ChangeTrendingProductionOrder = 'change-trending-production-order',
  RemoveTrendingProduction = 'remove-trending-production',
  ChangeProductionsSearchQuery = 'change-productions-search-query',
  StartSearchingProductions = 'start-searching-productions',
  FinishSearchingProductions = 'finish-searching-productions',
  LoadedTrending = 'loaded-trending',
  SearchTrending = 'search-trending',
  QueueTrendingForSaving = 'queue-trending-for-saving',
  StartSavingTrending = 'start-saving-trending',
  FinishSavingTrending = 'finish-saving-trending',
  ExitManageContent = 'exit-manage-content',
  ExitLiveEvents = 'exit-live-events',
  FetchedScheduledEvents = 'fetched-scheduled-events',
  FinishCreatingScheduledEvent = 'finish-creating-scheduled-event',
  FinishEditingScheduledEvent = 'finish-editing-scheduled-event',
  FinishDeletingScheduledEvent = 'finish-deleting-scheduled-event',
  MarkScheduledEventAsOld = 'mark-scheduled-event-as-old',
  ExitNotifications = 'exit-notifications',
  FetchedNotifications = 'fetched-notifications',
  FinishCreatingNotification = 'finish-creating-notification',
  FinishEditingNotification = 'finish-editing-notification',
  FinishDeletingNotification = 'finish-deleting-notification',
  MarkNotificationAsOld = 'mark-notification-as-old',
}

export default ActionType;

import React, { useContext, useEffect } from 'react';
import { useHistory, generatePath } from 'react-router';
import styles from './notification-block.module.css';
import Icons from '../icons';
import SquaredPurpleButton from '../squared-purple-button';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';
import { Paths } from '../../routes';
import { formatWithTimezone } from '../../utils/date-utils';
import config from '../../config';

const NotificationBlock: React.FC<{
  id?: number;
  title: string;
  description: string;
  releaseDate?: Date | number | string | null;
  disabled?: boolean;
  className?: string;
}> = ({
  id,
  title,
  description,
  releaseDate,
  disabled,
  className = '',
}) => {
  const {
    state: {
      newNotifications,
    },
    dispatch,
  } = useContext(StoreContext);
  const history = useHistory();

  useEffect(() => {
    if (!id || !newNotifications.includes(id)) return;

    setTimeout(() => {
      dispatch(actions.markNotificationAsOld(id));
    }, 5000);
  }, []);

  const isNew = !!id && newNotifications.includes(id);

  return (
    <div className={`${styles.block} ${className} ${disabled ? styles.disabled : ''}`}>
      <div
        className={`
          ${styles.innerBlock} 
          ${isNew ? styles.highlighted : styles.unhighlighted}
        `}
      >
        <div className="font-weight-bold">
          {title}
        </div>
        <div>
          {description}
        </div>
        <div className="d-flex flex-row align-items-center mt-2">
          <div className="d-flex flex-row align-items-center flex-grow-1">
            {releaseDate ? (
              <div className={styles.date}>
                <div>
                  {formatWithTimezone(releaseDate, 'MMM d, y', config.timeZone)}
                </div>
              </div>
            ) : (
              <div className={`${styles.date} ${styles.empty}`} />
            )}
            {releaseDate ? (
              <Icons.RightArrow className={styles.arrow} />
            ) : (
              <Icons.RightArrow className={`${styles.arrow} ${styles.empty}`} />
            )}
            {releaseDate ? (
              <div className={styles.date}>
                <div>
                  {formatWithTimezone(releaseDate, 'hh:mm a zzz', config.timeZone)}
                </div>
              </div>
            ) : (
              <div className={`${styles.date} ${styles.empty}`} />
            )}
          </div>
          <div className={styles.divider} />
          <div className="align-self-center">
            {id ? (
              <SquaredPurpleButton
                className={`${styles.editButton}`}
                onClick={() => {
                  history.push(generatePath(Paths.EditNotification, {
                    id,
                  }));
                }}
                disabled={disabled}
              >
                Edit
              </SquaredPurpleButton>
            ) : (
              <div className={`${styles.editButton} ${styles.empty}`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBlock;

import React from 'react';
import styles from './notification-input-header.module.css';

const NotificationInputHeader: React.FC<{
  input: string;
}> = ({
  input,
  children,
}) => {
  return (
    <div className={styles.inputHeader}>
      <div>
        <label className="font-weight-bolder">
          {children}
        </label>
      </div>
      <div className={styles.textCount}>
        characters count:&nbsp;
        {input.length}
      </div>
    </div>
  );
};

export default NotificationInputHeader;

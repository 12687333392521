import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './forgot-password-form.module.css';
import Services from '../../services';
import { Paths } from '../../routes';
import PurpleButton from '../purple-button';

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const history = useHistory();

  return (
    <form
      className={`${styles.form}`}
      onSubmit={(e) => {
        e.preventDefault();
        setSubmitting(true);
        setRequestError(false);
        Services.Administrators.forgotPassword(email).then((success) => {
          if (success) {
            history.push({
              pathname: Paths.VerifyResetToken,
              state: {
                email,
              },
            });
          } else {
            setRequestError(true);
          }
          setSubmitting(false);
        });
      }}
    >
      <div className="form-group mb-4">
        <label className="font-weight-bolder">Email Address</label>
        <input
          type="email"
          className={`${styles.input} form-control`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={submitting}
          required
        />
      </div>
      <div className="form-group mb-0 d-flex justify-content-between align-items-center">
        <Link className={styles.signin} to={Paths.Login}>
          Sign in
        </Link>
        <PurpleButton
          className={`${styles.submitButton}`}
          type="submit"
          disabled={submitting}
        >
          Send
        </PurpleButton>
      </div>
      <div className={styles.requestErrorContainer}>
        {requestError && (
          <div className={`${styles.requestError} text-right text-danger mt-3`}>
            Failed to send reset token.
          </div>
        )}
      </div>
    </form>
  );
};

export default ForgotPasswordForm;

import React from 'react';
import { Redirect, Switch, Route } from 'react-router';
import styles from './auth.module.css';
import { Icons } from '../../components';
import { SessionUtils } from '../../utils';
import { Paths } from '../../routes';
import Pages from '..';

const Auth: React.FC = () => {
  const accessToken = SessionUtils.retrieveAccessToken();

  return accessToken ? (
    <Redirect to={Paths.Manage} />
  ) : (
    <div className="container-fluid">
      <div className={`${styles.auth} row`}>
        <div className={`${styles.branding} col-md-6`}>
          <Icons.Brand className="" />
        </div>
        <div
          className={`${styles.formSection} col-md-6`}
        >
          <div className={`${styles.formPadding} ${styles.large}`}>
            <Switch>
              <Route path={Paths.Login}>
                <Pages.Login />
              </Route>
              <Route path={Paths.ForgotPassword}>
                <Pages.ForgotPassword />
              </Route>
              <Route path={Paths.ResetPassword}>
                <Pages.ResetPassword />
              </Route>
              <Route exact path={Paths.Auth}>
                <Redirect to={Paths.Login} />
              </Route>
              <Route path={Paths.Default}>
                <Redirect to={Paths.NotFound} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;

import React from 'react';

import searchingIcon from '../../../assets/icons/searching.gif';
import styles from './searching-icon.module.css';

const SearchingIcon: React.FC<{
  className?: string;
}> = ({
  className = '',
}) => {
  return (
    <img
      className={`${styles.searching} ${className}`}
      src={searchingIcon}
      alt="Searching..."
    />
  );
};

export default SearchingIcon;

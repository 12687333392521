import React from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { isValid } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import styles from './live-event-time-picker.module.css';

const LiveEventTimePicker: React.FC<{
  utcTime: Date | null;
  onTimeChange: (time: Date | null) => void;
  placeholder: string;
  disabled?: boolean;
  timeZone?: string;
}> = ({
  utcTime,
  onTimeChange,
  placeholder,
  disabled,
  timeZone,
}) => {
  const zonedTime = !timeZone || !utcTime || !isValid(utcTime)
    ? utcTime
    : utcToZonedTime(utcTime, timeZone);

  return (
    <KeyboardTimePicker
      disabled={disabled}
      variant="inline"
      value={zonedTime}
      onChange={(newTime) => {
        const utcNewTime = !timeZone || !newTime || !isValid(newTime)
          ? newTime
          : zonedTimeToUtc(newTime, timeZone);

        onTimeChange(utcNewTime);
      }}
      className={`${styles.timeInput} form-control`}
      placeholder={placeholder}
      InputProps={{
        classes: {
          root: `${styles.pickerRoot} pr-0`,
          input: styles.pickerInput,
        },
      }}
    />
  );
};

export default LiveEventTimePicker;

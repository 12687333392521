import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

/*
  TODO: According to Material UI's docs, for overrride to work with
  Typescript, we must add our own custom typings.
  So in order to, temporarily, avoid introducing custom typings to
  our project, we just simply declare it as any for now.

  https://material-ui-pickers.dev/guides/css-overrides
*/
const overrides: any = {
  MuiPickersDay: {
    hidden: {
      opacity: 0.3,
    },
    daySelected: {
      color: '#FFFFFF',
      backgroundColor: '#795DE7',
    },
  },
  MuiPickersClockPointer: {
    thumb: {
      borderColor: '#795DE7',
    },
    pointer: {
      backgroundColor: '#795DE7',
    },
    noPoint: {
      backgroundColor: '#795DE7',
    },
  },
  MuiPickersClock: {
    pin: {
      backgroundColor: '#795DE7',
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: '#795DE7',
    },
  },
};

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Nunito"',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  overrides: { ...overrides },
});

const LiveEventFormTheme: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={materialTheme}>
      {children}
    </ThemeProvider>
  );
};

export default LiveEventFormTheme;

import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import {
  TrendingProductionCard,
  DraggableCardDisplay,
  TrendingSearchBar,
  Icons,
  VodProviderModal,
} from '../../components';
import styles from './whats-poppin.module.css';
import { Paths } from '../../routes';
import { StoreContext } from '../../store/store';

const WhatsPoppin: React.FC = () => {
  const {
    state: {
      productionsData,
      trendingProductions,
      trendingSearchQuery,
      isTrendingLoaded,
      trendingProductionsBeingSaved,
      trendingSaveFailed,
    },
  } = useContext(StoreContext);
  const [searchResults, setSearchResults] = useState<Array<number>>([]);

  useEffect(() => {
    if (!trendingSearchQuery) {
      setSearchResults([...trendingProductions]);
      return;
    }

    setSearchResults(trendingProductions.filter((productionId) => {
      return productionsData[productionId]?.title
        .toLocaleLowerCase()
        .includes(trendingSearchQuery.toLocaleLowerCase());
    }));
  }, [trendingProductions, trendingSearchQuery, productionsData]);

  return (
    <div className="container-fluid">
      <div className="row justify-content-between align-items-center pb-5">
        <div className="col-6 d-flex">
          <TrendingSearchBar />
        </div>
        <div className={`${styles.saving} col-1 pl-0`}>
          {trendingProductionsBeingSaved ? (
            <span>Saving...</span>
          ) : trendingSaveFailed && (
            <span>Saving failed...</span>
          )}
        </div>
        <div className="col">
          <div className="row justify-content-end">
            <div className={`${styles.inactiveTab} col-auto pb-2 pt-2`}>
              <Link to={Paths.AddContent}>
                Add Content
              </Link>
            </div>
            <div className={`${styles.activeTab} col-auto pb-2 pt-2`}>
              What's Poppin'
            </div>
          </div>
        </div>
      </div>
      {isTrendingLoaded ? (
        <div className="row mb-3">
          <div className="col">
            <DraggableCardDisplay>
              {searchResults.map((productionId, index) => {
                return (
                  <TrendingProductionCard
                    key={productionsData[productionId].id}
                    id={productionsData[productionId].id}
                    poster={productionsData[productionId].poster}
                    type={productionsData[productionId].type}
                    genres={[...productionsData[productionId].genres]}
                    title={productionsData[productionId].title}
                    position={index}
                  />
                );
              })}
            </DraggableCardDisplay>
          </div>
        </div>
      ) : (
        <Icons.Loading className={`${styles.loading}`} />
      )}
      <VodProviderModal />
    </div>
  );
};

export default WhatsPoppin;

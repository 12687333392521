import { AdminApi } from './apis';
import { ApiResponse } from './apis/apis';

class LiveEventsService {
  public static getScheduledEvents = async (options: {
    endsAfter: string;
  }, accessToken: string) => {
    const response: ApiResponse<Array<{
      id: number;
      name: string;
      description: string;
      startDate: string;
      endDate: string;
    }>> = await AdminApi.getScheduledEvents({
      request: {
        endsAfter: options.endsAfter,
      },
      headers: {
        Authorization: accessToken,
      },
    });

    return response?.data.map((scheduledEvent) => {
      return {
        ...scheduledEvent,
        startDateTimestamp: new Date(scheduledEvent.startDate).getTime(),
        endDateTimestamp: new Date(scheduledEvent.endDate).getTime(),
      };
    }) || [];
  };

  public static save = async (liveEvent: {
    name: string;
    description: string;
    startDate: Date;
    endDate: Date;
  }, accessToken: string) => {
    try {
      const response: ApiResponse<{ id: number }> = await AdminApi.saveLiveEvent({
        request: {
          name: liveEvent.name,
          description: liveEvent.description,
          startDate: liveEvent.startDate.toISOString(),
          endDate: liveEvent.endDate.toISOString(),
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data.id;
    } catch (err) {
      return undefined;
    }
  };

  public static update = async (liveEvent: {
    id: number;
    name: string;
    description: string;
    startDate: Date;
    endDate: Date;
  }, accessToken: string) => {
    try {
      const response: ApiResponse<{ id: number }> = await AdminApi.updateLiveEvent({
        request: {
          name: liveEvent.name,
          description: liveEvent.description,
          startDate: liveEvent.startDate.toISOString(),
          endDate: liveEvent.endDate.toISOString(),
        },
        path: {
          id: liveEvent.id,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data.id;
    } catch (err) {
      return undefined;
    }
  };

  public static delete = async (id: number, accessToken: string) => {
    try {
      const response: ApiResponse<{ id: number }> = await AdminApi.deleteLiveEvent({
        path: {
          id,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return response?.data.id;
    } catch (err) {
      return undefined;
    }
  };
}

export default LiveEventsService;

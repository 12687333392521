import React, { createContext, useState } from 'react';

type Parameter = {
  productionId: number;
  productionName: string;
} | undefined;

const VodProviderPopupContext = createContext<[Parameter, (production: Parameter) => void]>([
  undefined,
  () => undefined,
]);

const VodProviderPopupContextProvider: React.FC = ({ children }) => {
  const [production, setProduction] = useState<Parameter>(undefined);

  return (
    <VodProviderPopupContext.Provider value={[production, setProduction]}>
      {children}
    </VodProviderPopupContext.Provider>
  );
};

export {
  VodProviderPopupContext,
  VodProviderPopupContextProvider,
};

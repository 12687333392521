import React from 'react';
import styles from './squared-dark-button.module.css';
import DarkButton from '../dark-button';

const SquaredDarkButton: React.FC<{
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
}> = ({
  className = '',
  disabled,
  onClick,
  type,
  children,
}) => {
  return (
    <DarkButton
      type={type}
      className={`${className} ${styles.squared}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </DarkButton>
  );
};

export default SquaredDarkButton;

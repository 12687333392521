import { Production, ScheduledEvent, Notification } from './initial-state';
import ActionType from './action-types';

export const trendProduction = (productionId: number) => ({
  type: ActionType.TrendProduction as const,
  payload: productionId,
});

export const undoTrendProduction = (productionId: number) => ({
  type: ActionType.UndoTrendProduction as const,
  payload: productionId,
});

export const changeTrendingProductionOrder = (
  productionId: number,
  position: number,
) => ({
  type: ActionType.ChangeTrendingProductionOrder as const,
  payload: {
    productionId,
    position,
  },
});

export const removeTrendingProduction = (productionId: number) => ({
  type: ActionType.RemoveTrendingProduction as const,
  payload: productionId,
});

export const changeProductionsSearchQuery = (query: string) => ({
  type: ActionType.ChangeProductionsSearchQuery as const,
  payload: query,
});

export const startSearchingProductions = (query: string, identifier: string) => ({
  type: ActionType.StartSearchingProductions as const,
  payload: {
    query,
    identifier,
  },
});

export const finishSearchingProductions = (
  identifier: string,
  productions: Array<Production>,
  page: number,
) => ({
  type: ActionType.FinishSearchingProductions as const,
  payload: {
    identifier,
    productions,
    page,
  },
});

export const startLoadingMoreProductions = (query: string, identifier: string) => ({
  type: ActionType.StartLoadingMoreProductions as const,
  payload: {
    query,
    identifier,
  },
});

export const loadedMoreProductions = (
  identifier: string,
  productions: Array<Production>,
  page: number,
) => ({
  type: ActionType.LoadedMoreProductions as const,
  payload: {
    identifier,
    productions,
    page,
  },
});

export const loadedTrending = (productions: Array<Production>) => ({
  type: ActionType.LoadedTrending as const,
  payload: productions,
});

export const searchTrending = (query: string) => ({
  type: ActionType.SearchTrending as const,
  payload: query,
});

export const queueTrendingForSaving = () => ({
  type: ActionType.QueueTrendingForSaving as const,
});

export const startSavingTrending = () => ({
  type: ActionType.StartSavingTrending as const,
});

export const finishSavingTrending = (success: boolean) => ({
  type: ActionType.FinishSavingTrending as const,
  payload: success,
});

export const exitManageContent = () => ({
  type: ActionType.ExitManageContent as const,
});

export const exitLiveEvents = () => ({
  type: ActionType.ExitLiveEvents as const,
});

export const fetchedScheduledEvents = (scheduledEvents: Array<ScheduledEvent>) => ({
  type: ActionType.FetchedScheduledEvents as const,
  payload: scheduledEvents,
});

export const finishCreatingScheduledEvent = (scheduledEvent: ScheduledEvent) => ({
  type: ActionType.FinishCreatingScheduledEvent as const,
  payload: scheduledEvent,
});

export const finishEditingScheduledEvent = (scheduledEvent: ScheduledEvent) => ({
  type: ActionType.FinishEditingScheduledEvent as const,
  payload: scheduledEvent,
});

export const finishDeletingScheduledEvent = (id: number) => ({
  type: ActionType.FinishDeletingScheduledEvent as const,
  payload: id,
});

export const markScheduledEventAsOld = (id: number) => ({
  type: ActionType.MarkScheduledEventAsOld as const,
  payload: id,
});

export const exitNotifications = () => ({
  type: ActionType.ExitNotifications as const,
});

export const fetchedNotifications = (notifications: Array<Notification>) => ({
  type: ActionType.FetchedNotifications as const,
  payload: notifications,
});

export const finishCreatingNotification = (notification: Notification) => ({
  type: ActionType.FinishCreatingNotification as const,
  payload: notification,
});

export const finishEditingNotification = (notification: Notification) => ({
  type: ActionType.FinishEditingNotification as const,
  payload: notification,
});

export const finishDeletingNotification = (id: number) => ({
  type: ActionType.FinishDeletingNotification as const,
  payload: id,
});

export const markNotificationAsOld = (id: number) => ({
  type: ActionType.MarkNotificationAsOld as const,
  payload: id,
});

import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './login.module.css';
import { PurpleButton } from '../../components';
import { SessionUtils } from '../../utils';
import { Paths } from '../../routes';
import Services from '../../services';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const history = useHistory();

  return (
    <div className={`${styles.login}`}>
      <div className={`${styles.title} text-center`}>
        PopViewers
      </div>
      <form
        className={`${styles.form}`}
        onSubmit={(e) => {
          e.preventDefault();
          setLoggingIn(true);
          setLoginError(false);
          Services.Administrators.login({ email, password }).then((newAccessToken) => {
            if (newAccessToken) {
              SessionUtils.storeAccessToken(newAccessToken);
              history.push(Paths.Manage);
            } else {
              setLoginError(true);
            }
            setLoggingIn(false);
          });
        }}
      >
        <div className="form-group mb-4">
          <label className="font-weight-bolder">Email Address</label>
          <input
            type="email"
            className={`${styles.input} form-control`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loggingIn}
            required
          />
        </div>
        <div className="form-group mb-4">
          <label className="font-weight-bolder">Password</label>
          <input
            type="password"
            className={`${styles.input} form-control`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loggingIn}
            required
          />
        </div>
        <div className="form-group mb-0 d-flex justify-content-between align-items-center">
          <Link className={styles.forgotPassword} to={Paths.ForgotPassword}>
            Forgot password?
          </Link>
          <PurpleButton
            className={`${styles.submitButton}`}
            type="submit"
            disabled={loggingIn}
          >
            Sign In
          </PurpleButton>
        </div>
        <div className={styles.loginErrorContainer}>
          {loginError && (
            <div className={`${styles.loginError} text-right text-danger mt-3`}>
              The user isn't authorized to access the platform.
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;

export const retrieveAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem('access_token', accessToken);
};

export const clearAccessToken = () => {
  localStorage.removeItem('access_token');
};

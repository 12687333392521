import React from 'react';
import styles from './purple-button.module.css';

const PurpleButton: React.FC<{
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
}> = ({
  className = '',
  disabled,
  onClick,
  type = 'button',
  children,
}) => {
  return (
    <button
      type={type}
      className={`${styles.button} ${className} btn`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PurpleButton;

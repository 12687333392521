import React, { useState } from 'react';
import {
  Link,
  Switch,
  Route,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { Popover } from '@material-ui/core';

import styles from './top-header-bar.module.css';
import { Paths } from '../../routes';
import Icons from '../icons';
import { SessionUtils } from '../../utils';
import Services from '../../services';

const TopHeaderBar: React.FC = () => {
  const history = useHistory();
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const [loggingOut, setLoggingOut] = useState(false);

  const accessToken = SessionUtils.retrieveAccessToken();
  const openOption = !!anchorElement || loggingOut;

  return !accessToken ? (
    <Redirect to={Paths.Auth} />
  ) : (
    <div className={`${styles.header} container-fluid mb-5`}>
      <div className="row">
        <div className="col-1 align-self-center" />
        <div className="col-10 align-self-center">
          <nav className="row navbar px-0 navbar-expand-lg navbar-light justify-content-between">
            <div className="col-auto align-self-center">
              <Link className="navbar-brand" to={Paths.Manage}>
                <Icons.PVIcon />
              </Link>
            </div>
            <div className="col align-self-center">
              <div className="row justify-content-end">
                <div className="col-auto align-self-center px-4">
                  <Switch>
                    <Route path={Paths.Manage}>
                      <div className={`${styles.activeLink} text-right`}>
                        Manage Content
                      </div>
                    </Route>
                    <Route>
                      <div className={`${styles.link} text-right`}>
                        <Link className="unstyle-link" to={Paths.Manage}>
                          Manage Content
                        </Link>
                      </div>
                    </Route>
                  </Switch>
                </div>
                <div className="col-auto align-self-center px-4">
                  <Switch>
                    <Route path={Paths.LiveEvents}>
                      <div className={`${styles.activeLink} text-right`}>
                        Events
                      </div>
                    </Route>
                    <Route>
                      <div className={`${styles.link} text-right`}>
                        <Link className="unstyle-link" to={Paths.CreateEvent}>
                          Events
                        </Link>
                      </div>
                    </Route>
                  </Switch>
                </div>
                <div className="col-auto align-self-center px-4">
                  <Switch>
                    <Route path={Paths.Notifications}>
                      <div className={`${styles.activeLink} text-right`}>
                        Notifications
                      </div>
                    </Route>
                    <Route>
                      <div className={`${styles.link} text-right`}>
                        <Link className="unstyle-link" to={Paths.CreateNotification}>
                          Notifications
                        </Link>
                      </div>
                    </Route>
                  </Switch>
                </div>
                <div className="col-auto align-self-center px-4">
                  <button
                    type="button"
                    className={styles.optionButton}
                    onClick={(e) => setAnchorElement(e.currentTarget)}
                  >
                    <Icons.MoreOptions />
                  </button>
                  <Popover
                    open={openOption}
                    anchorEl={anchorElement}
                    onClose={() => setAnchorElement(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <button
                      type="button"
                      className={styles.logoutButton}
                      onClick={() => {
                        setLoggingOut(true);
                        Services.Administrators.logout(accessToken).then((success) => {
                          if (success) {
                            SessionUtils.clearAccessToken();
                            history.push(Paths.Auth);
                          }
                          setLoggingOut(false);
                        });
                      }}
                      disabled={loggingOut}
                    >
                      Log out
                    </button>
                  </Popover>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="col-1 align-self-center" />
      </div>
    </div>
  );
};

export default TopHeaderBar;
